import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StyledMenu } from "../header/header.styled";

const Menu = ({ open, ...props }) => {

  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <ul>
        <li>
          <Link to="/" tabIndex={tabIndex}>
            <FontAwesomeIcon icon={[ "fas", "home" ]}/>
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to="/mixing" tabIndex={tabIndex}>
            <FontAwesomeIcon icon={[ "fas", "sliders-h" ]}/>
            <span>Mixing</span>
          </Link>
        </li>
        <li>
          <Link to="/mastering" tabIndex={tabIndex}>
            <FontAwesomeIcon icon={[ "fas", "volume-up" ]}/>
            <span>Mastering</span>
          </Link>
        </li>
        <li>
          <Link to="/samples" tabIndex={tabIndex}>
            <FontAwesomeIcon icon={[ "fas", "vial" ]}/>
            <span>Samples</span>
          </Link>
        </li>
        <li>
          <Link to="https://www.facebook.com/pg/SavviProductions/reviews/" rel="noreferrer" target="_blank" tabIndex={tabIndex}>
            <FontAwesomeIcon icon={[ "fas", "edit" ]}/>
            <span>Reviews</span>
          </Link>
        </li>
        <li>
          <Link to="/contact" tabIndex={tabIndex}>
            <FontAwesomeIcon icon={[ "fas", "envelope-open-text" ]}/>
            <span>Contact</span>
          </Link>
        </li>
      </ul>
    </StyledMenu>
  );
};

export default Menu;
