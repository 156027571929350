import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import siteImage from "../images/Logo Small Black.jpg";

function SEO({ description, lang, meta, image, title }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          image
        }
      }
    }
  `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image || site.siteMetadata.image;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "description",
            content: metaDescription,
        },
        {
          property: "og:title",
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata.author,
        },
        {
          name: "twitter:title",
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ]
          .concat(
            metaImage
              ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: 1080,
                },
                {
                  name: "og:image:height",
                  content: 1080,
                },
              ]
              : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
          )
          .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
  image: `${siteImage}`
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default SEO;
