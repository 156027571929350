import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialMedia = () => {
  return (
    <ul>
      <li>
        <a href="https://facebook.com/SavviProductions" rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={[ "fab", "facebook-f" ]}/>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/savvipro" rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={[ "fab", "twitter" ]}/>
        </a>
      </li>
      <li>
        <a href="https://instagram.com/savviproductions" rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={[ "fab", "instagram" ]}/>
        </a>
      </li>
      <li>
        <a href="https://youtube.com/SavviProductions" rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={[ "fab", "youtube" ]}/>
        </a>
      </li>
    </ul>
  );
};

export default SocialMedia;
