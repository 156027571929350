import React from "react";
import "./footer.scss";
import SocialMedia from "./socialMedia";
const Footer = () => {

  return (
    <footer className="footer">
      <p className="title">Stay In Touch</p>
      <SocialMedia
        icon="facebook-f"
        link="https://facebook.com/SavviProductions"
      />
      <p className="copyrights">© {new Date().getFullYear()} SavviProductions LLC - All Rights Reserved</p>
    </footer>
  );

};

export default Footer;
