import styled from "styled-components";

export const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  height: 5em;
  width: 100%;
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;

  @media screen and (min-width: 60em) {
    transform: translateX(-50%);
    left: 50%;
    max-width: 64em;
  }
  @media screen and (max-width: 959px) {
    padding: 1em 2em;
  }

  a {
    z-index: 1;
  }

  svg {
    height: 60px;
    width: 60px;
  }
`;

export const StyledNavSection = styled.section`
  background: none;
  position: absolute;

  @media screen and (min-width: 60em) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 5em;
    padding: 1em 2em;
    width: 100%;
    max-width: 64em;
    right: 50%;
    transform: translateX(50%);
  }

  @media screen and (max-width: 959px) {
    width: 15em;
    height: 100%;
    background: #0E0D0C;
    transition: left .4s ease;
    z-index: 9;
    left: ${({ open }) => open ? "calc(100% - 15em)" : "100%"};
  }
`;

export const StyledMenu = styled.nav`
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0;
    transition: all 0.3s ease;
  }

  li {
    margin-right: 1.5em;
    margin-bottom: 0;
  }

  li:last-child {
    margin-right: 0;
  }

  a {
    font: bold .8em Catamaran;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;

  }

  a:hover {
    color: #BE2026;
  }

  svg {
    font-size: .9rem;
  }

  span {
    vertical-align: top;
    position: relative;
    left: 5px;
  }


  @media screen and (max-width: 959px) {
    // height: 100%;
    width: 100%;

    ul {
      box-sizing: border-box;
      flex-direction: column;
      width: 100%;
      top: 0;
      padding: 0;
      margin-top: 4em;
      margin-left: 1.5em;
      margin-bottom: 0;
      opacity: ${({ open }) => open ? "1" : "0"};
      transition: opacity .8s ease;
    }

    a {
      display: block;
      padding: 0.5rem 0;
    }
  }
`;

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  span {
    width: 2rem;
    height: 0.20rem;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? "rotate(45deg)" : "rotate(0)"};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? "0" : "1"};
      transform: ${({ open }) => open ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
  @media screen and (min-width: 60em) {
    display: none;
  }
`;
