import React from "react";
import PropTypes from "prop-types";

import SEO from "../seo";
import Header from "../header/header";
import Footer from "../footer/footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "../../styles/main.scss";
import "./layout.scss";

library.add(fas, fab);

const Layout = ({ title, description, image, children }) => {

  return (
    <main className="site">
      <Header/>
      <SEO title={title} description={description} image={image}/>
      <div className="main-content">
        {children}
      </div>
      <Footer/>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
