import React, { useState } from "react";
import { Link } from "gatsby";
import { StyledHeader, StyledNavSection } from "./header.styled";
import { StyledOverlay } from "../overlay.styled";

import Menu from "../menu/menu";
import Burger from "../menu/Burger";

const Header = () => {

  const [open, setOpen] = useState(false);
  const menuId = "main-menu";

  return (
    <>
      <StyledHeader>
        <Link to="/">
          <svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1">
            <title>Logo Small Copy 3</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Logo-Small-Copy-3" fill-rule="nonzero" fill="#be2026">
                <g id="Symbol" transform="translate(9.000000, 0.000000)">
                  <polygon id="XMLID_17_" points="26.0484755 6.20075386 9.12526211 15.6910265 51.1014678 40.3458255 51.1014678 45.9537139 1.32731085 17.4497133 1.32731085 14.7950916 25.9489272 0.0619411731 50.7032745 14.3968984 50.7696401 33.6429057 20.5401354 15.9233059 26.1480238 12.6713943 46.3895143 24.1526331 45.991321 18.4783792"></polygon>
                  <polygon id="XMLID_16_" points="0 23.9535365 0 30.0591664 42.1089368 54.0834929 25.6170994 64.1378726 6.00608161 52.8557303 5.84016775 45.4227895 26.114841 56.8376629 30.5613324 53.9839445 0 36.5961724 0 55.7094486 25.0861751 69.9448575 49.774157 55.510352 49.774157 52.6566337"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </Link>
        <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
      </StyledHeader>
      <StyledNavSection open={open}>
        <Menu open={open} setOpen={setOpen} id={menuId} />
      </StyledNavSection>
      <StyledOverlay open={open}/>
    </>
  );
};

export default Header;
