import styled from "styled-components";

export const StyledOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: ${({ open }) => open ? "8" : "-1"};
  background: #0E0D0C;
  transition: opacity 0.2s linear;
  opacity: ${({ open }) => open ? "0.5" : "0"};
`;
